  <template>
    <div class="h-screen overflow-x-hidden ">
      <NavInit />

      <!-- Hero text -->
      <div class=" ml-2 lg:ml-8 flex flex-col lg:flex-row mt-32 lg:mt-32 px-6 w-full lg:space-x-10 ">
        <!-- Text Section -->
        <div class="space-y-10">
          <div class="lg:text-left  space-y-2 w-full">
            <p class="text-4xl lg:text-7xl font-rockinsoda text-navy">HANGA </p>
            <p class="text-4xl lg:text-7xl font-rockinsoda text-navy">Sexual & Reproductive </p> 
            <p class="text-4xl lg:text-7xl font-rockinsoda text-navy">Health (SRH) PROGRAM</p>  
          </div>

          <!-- Description and Button Section -->
          <div class="text-justify mt-4 lg:mt-0 space-y-8 w-full lg:w-2/3">
            <p class="text-base lg:text-2xl font-gramatika text-navy">
              Exploring opportunities that exist for tech-enabled  startups to solve  SRH issues in Sub Saharan Africa.
                        </p>
          
          </div>
        </div>
      </div>

      <!-- Hero image -->
      <div class="relative w-full flex-shrink-0 mt-12  p-4 lg:p-10">
        <img src="../assets/naz.jpg"

          class="w-full  h-[40vh] lg:h-[120vh] object-cover ">
      </div>

      
      <div
        class=" ml-2 lg:ml-10  flex flex-col lg:flex-row  w-full  lg:mt-16 px-2 p-0  lg:p-2 lg:space-x-4 ">
        <!-- Text Section -->
        <div class="lg:text-left w-full lg:w-2/4 space-y-6 mt-8 lg:mt-20 ">
          <p class="text-3xl lg:text-6xl font-rockinsoda text-navy">Hanga SRH Program is back<br> and bigger than ever!  </p>

          <div class="flex  lg:justify-start">
            <button class="p-4 px-6 font-gramatika bg-tangerine text-white hover:bg-tangerine-dark transition-all" ref="button">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScSWdBKEqUkb3wyvAJXSDoBC1PU6le4NNwi9kpXQ2kVtfm0fg/viewform" target="_blank" class="mt-6">Start Your application</a>
          
            </button>
          </div>
        </div>

        <!-- Description and Button Section -->
        <div class="text-justify mt-4 lg:mt-0 space-y-8 w-full lg:w-2/4">
          <p class="text-base lg:text-lg font-gramatika text-darkGray mr-6 lg:mr-24">
            The program was conceived by the Government of Rwanda in 2022 through HangaPitchFest to explore the opportunity that exists for technology enabled 
            startups to solve key issues in SRH to accelerate better outcomes.

          </p>

          <p class="text-base lg:text-lg font-gramatika text-darkGray mr-6 lg:mr-24">
            in 2023 we scouted, selected, and provided technical and business support to <span class="font-bold"> startups based in 11  Sub-Saharan African countries</span> working on 
            innovative tech-enabled solutions that are addressing challenges in ways that break the mold in SRH. With the support of the Hanga SRH program,
             the 20 ventures maximized their impact at scale by raising over<span class="font-bold"> $1.4 million in funding, generate over $55,000 in revenue and  impact over 33,500 people.</span>
          </p>

        </div>
      </div>


      <div class="overflow-hidden">
        <div class=" w-full lg:w-full  mt-12 lg:mt-12 ">
          
            <div class="flex flex-col lg:flex-row  lg:space-x-10 space-y-6 lg:space-y-0 p-4 lg:p-10" >

              <div class="w-full ">
                <div class=" h-[40vh] lg:h-[70vh] flex flex-shrink-0  ">
                  <img src="../assets/img1.png" class="w-full h-full object-cover">
                </div>
              </div>

              <div class="w-full ">
                <div class=" h-[40vh] lg:h-[70vh]  flex flex-shrink-0 ">
                  <img src="../assets/img2.png" class="w-full h-full object-cover">
                </div>
              </div>

            </div>
          
        </div>

        <!-- next double section -->
        <div class="flex items-center justify-center  mt-20">
          <div class="space-y-4 text-center">
          <p class="text-tangerine  font-grammatika text-md lg:text-xl">CALL FOR APPLICATIONS</p>
          <p class="font-rockinsoda text-navy text-2xl lg:text-4xl">BE PART OF HANGA SRH COHORT 2 !</p>

            <div class="text-darkGray font-grammatika  text-md lg:text-xl">
             <p>We are thrilled to launch Hanga SRH Cohort 2, a bigger, bolder, and catalytic opportunity for 20 entrepreneurs in Sub-</p>
             <p>Saharan Africa looking to improve and scale their innovative and tech-driven SRH solutions.</p>
            </div>
          </div>
        </div>


        <!-- next section -->

        <div class="flex flex-col lg:flex-row p-6 lg:p-16 lg:space-x-10 space-y-6 lg:space-y-0 lg:mt-12">

            

          <div class="flex-1  mt-12 xl:mt-2  md:mt-8 max-w-full md:max-w-[50%]">
            <div class="space-y-2   ">
              <p class="text-lg md:text-xl font-grammatika font-semibold text-navy"> Our Target Themes </p>
            </div> 
          
        
            <div class="mt-2">
              <div class="text-justify space-y-4 w-full">
                  <div class="">
                <p class="text-darkGray  text-lg lg:text-xl font-gramatika">We're looking for early-stage,tech-enabled </p>
                <p  class="text-darkGray text-lg lg:text-xl font-gramatika"> innovations:</p>
              </div>
                <ul class="list-disc text-lg [&>li::marker]:text-4xl ml-6 mt-6">  
                  <li class="text-darkGray font-gramatika"> <span class="font-bold">Improving maternal health</span>, beyond antenatal care, ensuring safe pregnancy, childbirth, and postpartum care</li>
                  <li class="text-darkGray font-gramatika"> <span class="font-bold">Tackling unsafe abortion </span> by removing barriers to safe abortion services and post-abortion care</li> 
                  <li class="text-darkGray font-gramatika"><span class="font-bold">Targeting </span> young people (10-24 years) with <span class="font-bold">youth-friendly SRH information and services.</span></li> 
                  <li class="text-darkGray font-gramatika"> <span class="font-bold"> Tackling gender-based violence (GBV) </span> by preventing and responding to sexual and gender-based violence through innovative solutions</li>
                  <li class="text-darkGray font-gramatika"> <span class="font-bold"> Meeting  </span> the  <span class="font-bold">need for contraception  </span> by increasing access to contraception and comprehensive SRH education</li>
                 </ul>

                 <p class="text-darkGray  text-lg lg:text-xl font-gramatika mt-4" >
                  We know that this is not the full extent of themes which constitute improving SRH outcomes, and would love to hear from  innovators with ideas beyond the mentioned themes. 

                 </p>

                 <button class="font-gramatika text-xl sm:text-2xl text-navy transition-all" ref="button">
                  <div class="flex justify-center lg:justify-start space-x-4">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScSWdBKEqUkb3wyvAJXSDoBC1PU6le4NNwi9kpXQ2kVtfm0fg/viewform" target="_blank">APPLY NOW</a>
                    <img src="../assets/Arrow 1.svg" alt="Arrow Icon">
                  </div>
                </button>
              </div>
            
            </div>
          </div>

          <div class="flex-1 h-[300px] sm:mt-10 md:h-[100vh] space-y-4 md:mt-10 xl:mt-20 2xl:mt-36 max-w-full md:max-w-[50%]">
            <img src="../assets/img3.png" class="w-full h-[30vh] lg:h-[50vh]  object-cover">
            <img src="../assets/img4.png" class="w-full   h-[30vh] lg:h-[50vh]  object-cover">

          </div>
         
       
        </div>

        <!-- Hanga SRH Network -->
   <!-- Selection process -->
   <div class="relative h-auto min-h-[600px]">
    <div class="mt-20 py-16  sm:mt-32 bg-cover h-full"
      :style="{ backgroundImage: `url(${require('@/assets/notched.svg')})` }">

      <div class="lg:text-left  w-2/3 lg:w-2/4 space-y-2 ml-6 lg:ml-16 mt-24">
        <p class="text-3xl lg:text-4xl font-rockinsoda text-white">What's in it for you?</p>
        <p class="text-md lg:text-lg font-grammatika text-white">If selected to join the Hanga SRH Program, you will have access </p>
        <p class="text-md lg:text-lg font-grammatika text-white">to the following:</p>

      </div>

      <div class="px-4 sm:px-6 md:px-8 lg:px-12 mt-8 md:mt-12 lg:mt-16 flex flex-col space-y-16 mb-16">
        <!-- First row -->
        <div class="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
          <div class="w-full md:w-1/3 space-y-4">
            <img src="../assets/dollar.svg" alt="" class="w-[50px] h-[50px]">
            <p class="text-xl lg:text-xl font-gramatika font-bold text-white">Funding</p>
             
            <p class="text-white font-gramatika">Grant funding valued at $10,000 USD with the scope to increase up to $30,000 USD for the top performing
               start-ups in the program selected to proceed to the acceleration phase.</p>
          </div>
          <div class="w-full md:w-1/3 space-y-4">
            <img src="../assets/books.svg" alt="" class="w-[50px] h-[50px]">
            <p class="text-xl lg:text-xl font-gramatika text-white font-bold">Expert-Led Incubation
            </p>
            <p class="text-white font-gramatika">a 2 months, cohort-based learning curriculum, covering entrepreneurship, evidence and SRH</p>
          </div>
          <div class="w-full md:w-1/3 space-y-4">
            <img src="../assets/msg.svg" alt="" class="w-[70px] h-[70px]">
            <p class="text-xl lg:text-xl font-gramatika text-white font-bold">Bespoke Support <br>
              Intentions Assessment</p>
            <p class="text-white font-gramatika">Gain 6 months of structured coaching from SRH and venture-building experts designed to accelerate your growth and scale your impact.</p>
          </div>
        </div>
        <!-- Second row -->
        <div class="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
          <div class="w-full md:w-1/3 space-y-4">
            <img src="../assets/earth.svg" alt="" class="w-[50px] h-[50px]">
            <p class="text-xl lg:text-xl font-gramatika text-white font-bold">Peer Learning & <br> Networking
            </p>
            <p class="text-white font-gramatika">Connect with like-minded entrepreneurs to share insights, troubleshoot challenges,
               and explore opportunities for collaboration and growth.</p>
          </div>
          <div class="w-full md:w-1/3 space-y-4">
            <img src="../assets/house.svg" alt="" class="w-[50px]">
            <p class="text-xl lg:text-xl font-gramatika text-white font-bold">Exclusive Market Access</p>
            <p class="text-white font-gramatika">Leverage our powerful network of policymakers and healthcare stakeholders.</p>
          </div>
          <div class="w-full md:w-1/3 space-y-4">
            <img src="../assets/money.svg" alt="" class="w-[50px]">
            <p class="text-xl lg:text-xl font-gramatika text-white font-bold">Investor Readiness</p>
            <p class="text-white font-gramatika">Connect with funders, donors, and ecosystem leaders to accelerate growth</p>
          </div>

        </div>

         <!-- third row -->
         <div class="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">

          <div class="w-full md:w-1/3 space-y-4">
            <img src="../assets/rockt.svg" alt="" class="w-[50px] h-[50px]">
            <p class="text-xl lg:text-xl font-gramatika text-white font-bold">Pilot & Scale
            </p>
            <p class="text-white font-gramatika">Tap into strategic partnerships to test and launch your solution in new market.</p>
          </div>
         
         
        </div>
      </div>


    </div>
  </div>

   

    <!-- Quotes section -->

    <div class="p-4 lg:p-10 mt-10 lg:mt-20">
      <div class="flex">
      <div class="w-[70%]">
        <p class="text-navy font-bold text-3xl lg:text-5xl font-rockinsoda">INSIGHTS FROM  HANGA SRH </p>
        <p class="text-navy font-bold text-3xl lg:text-5xl font-rockinsoda">ALUMNI</p>
      </div>
      <div>
        <router-link to="/cohort">
        <p class="text-navy text-md lg:text-xl font-grammatika">Check out <span class="underline font-medium">Our 2024 Cohort</span></p>
      </router-link>
      </div>
    </div>

      <div class="flex flex-col lg:flex-row lg:space-x-10 space-y-4 lg:space-y-0 mt-8 lg:mt-16">

       
          <div class=" text-center border -2 border-lightTangerine p-4 ">
            <p class="text-navy text-lg text-justify font-gramatika p-2">
              <span class="font-rockinsoda text-tangerine text-5xl ml-2">"</span>
              Having dedicated years to the SRH field, I found myself reliant on unpredictable grant funding. However, since joining Hanga SRH, I've discovered innovative 
              avenues to address critical SRHR challenges while creating sustainable revenue streams for my startup.
            </p>
            <div class="flex space-x-2 ml-2">
              <hr class="w-[50px] border-2 mt-2">
              <p class="font-rockinsoda text-navy text-xl">AASAP, Rwanda</p>
            </div>
          </div>
        
          <div class=" text-center border -2 border-lightTangerine p-4 ">
            <p class="text-navy text-lg text-justify font-gramatika p-2">
              <span class="font-rockinsoda text-tangerine text-5xl ml-2">"</span>
              Throughout the Hanga SRH Program one of our key achievements was that it enabled us to build our social enterprise model.
               As a non-profit, it is really important for us to look at how to diversify funding because relying solely on grant funding is not sustainable.
            </p>
            <div class="flex space-x-2 ml-2">
              <hr class="w-[50px] border-2 mt-2">
              <p class="font-rockinsoda text-navy text-xl">GRIT, South Africa</p>
            </div>
          </div>

          <div class=" text-center border -2 border-lightTangerine p-4 ">
            <p class="text-navy text-lg text-justify font-gramatika p-2">
              <span class="font-rockinsoda text-tangerine text-5xl ml-2">"</span>
              What sets Hanga SRH apart is its holistic approach to venture support. It goes beyond funding by providing hands-on coaching, tailored business development, 
              and a collaborative learning environment that empowers entrepreneurs to refine their solutions in real time.
            </p>
            <div class="flex space-x-2 ml-2">
              <hr class="w-[50px] border-2 mt-2">
              <p class="font-rockinsoda text-navy text-xl">Cohort 1 Coach, Joanna Nicholas</p>
            </div>
          </div>

       
      </div>
    </div>

      <!-- our timeline section -->
      <div class=" ml-6 lg:ml-10 mt-24 lg:mt-20 ">
    <p class=" text-center lg:text-left text-3xl  lg:text-5xl font-rockinsoda text-navy">Our Timeline</p>
   </div>
   <div class="relative mt-20 w-full px-4 lg:px-2 flex flex-col lg:flex-row justify-between items-start">
    <div class="w-full px-4 lg:px-8">
        <div class="flex flex-col lg:flex-row lg:space-x-2"> 
      <!-- OCTOBER 20TH -->
       <div class="space-y-4">
                <p class="text-navy text-gramatika font-medium text-center lg:text-left">18 MARCH 2025</p>
                <div class="flex justify-center lg:justify-start space-x-2">
                    <img src="../assets/Ellipse 11.svg" alt="">
                    <img src="../assets/navyline.svg" alt="">
                </div>
                <div class="bg-timelinebg flex items-center justify-center lg:justify-start pl-8 pr-10 py-4 rounded-md">
                    <img src="../assets/srh-rocket.svg" alt="">
                    <p class="text-navy text-md font-medium font-gramatika">Applications Open</p>
                </div>
            </div> 

      <!-- OCTOBER 31ST -->
       <div class="space-y-4 mt-6 lg:mt-0">
                <p class="text-navy text-gramatika font-medium text-center lg:text-left">23 MARCH 2025</p>
                <div class="flex justify-center lg:justify-start space-x-2">
                    <img src="../assets/Ellipse 11.svg" alt="">
                    <img src="../assets/navyline.svg" alt="">
                </div>
                <div class="bg-timelinebg flex items-center justify-center lg:justify-start pl-8 pr-10 py-4 rounded-md">
                    <img src="../assets/info.svg" alt="">
                    <p class="text-navy text-md font-medium font-gramatika">Info Session(FR)</p>
                </div>
            </div> 

      <!-- NOVEMBER 15TH -->
    - <div class="space-y-4 mt-6 lg:mt-0">
                <p class="text-navy text-gramatika font-medium text-center lg:text-left"> 6 APRIL 2025</p>
                <div class="flex justify-center lg:justify-start space-x-2">
                    <img src="../assets/Ellipse 11.svg" alt="">
                    <img src="../assets/navyline.svg" alt="">
                </div>
                <div class="bg-timelinebg flex items-center justify-center lg:justify-start pl-8 pr-10 py-4 rounded-md">
                    <img src="../assets/info.svg" alt="">
                    <p class="text-navy text-md font-medium font-gramatika">Info Session(EN)</p>
                </div>
            </div> 

      <!-- NOVEMBER 20TH - 21ST -->
       <div class="space-y-4 mt-6 lg:mt-0">
                <p class="text-navy text-gramatika font-medium text-center lg:text-left">18 APRIL 2025</p>
                <div class="flex justify-center lg:justify-start space-x-2">
                    <img src="../assets/Ellipse 11.svg" alt="">
                    <img src="../assets/navyline.svg" alt="">
                </div>
                <div class="bg-timelinebg flex items-center justify-center lg:justify-start pl-8 pr-10 py-4 rounded-md">
                    <img src="../assets/close.svg" alt="">
                    <p class="text-navy text-md font-medium font-gramatika">Application Close</p>
                </div>
            </div>

               <!-- NOVEMBER 20TH - 21ST -->
       <div class="space-y-4 mt-6 lg:mt-0">
                <p class="text-navy text-gramatika font-medium text-center lg:text-left">EARLY MAY 2025</p>
                <div class="flex justify-center lg:justify-start space-x-2">
                    <img src="../assets/Ellipse 11.svg" alt="">
                    <img src="../assets/navyline.svg" alt="">
                </div>
                <div class="bg-timelinebg flex items-center justify-center lg:justify-start pl-8 pr-10 py-4 rounded-md">
                    <img src="../assets/info.svg" alt="">
                    <p class="text-navy text-md font-medium font-gramatika">Cohort Selected</p>
                </div>
      </div>

      <div class="space-y-4">
        <p class="text-navy text-gramatika font-medium text-center lg:text-left">JUNE 2025</p>
        <div class="flex justify-center lg:justify-start space-x-2">
            <img src="../assets/Ellipse 11.svg" alt="">
            <img src="../assets/navyline.svg" alt="">
        </div>
        <div class="bg-timelinebg flex items-center justify-center lg:justify-start pl-4 pr-20 py-4 rounded-md">
            <img src="../assets/info.svg" alt="">
            <p class="text-navy text-md font-medium font-gramatika">Incubation begins </p>
        </div>
    </div> 



    </div>
     </div>
</div> 
    <!-- send in your application -->
    <div class="flex items-center justify-center w-full mt-16 lg:mt-32 px-4">
  <div class="text-center w-full lg:w-2/3 space-y-6">
      <div>
          <p class="font-rockinsoda text-navy text-4xl lg:text-7xl leading-tight">SEND IN YOUR</p>
          <p class="font-rockinsoda text-navy text-4xl lg:text-7xl leading-tight">APPLICATIONS</p>
      </div>
      <div>
          <p class="text-gray-500 text-base lg:text-xl font-gramatika leading-relaxed">
            Are you driving innovation in Africa's SRH space? Apply now to be part of the 20 high-potential startups selected for this game-changing program!          </p>
      </div>
      <button class="p-2 px-4 font-gramatika bg-tangerine text-white hover:bg-tangerine-dark transition-all">
        
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScSWdBKEqUkb3wyvAJXSDoBC1PU6le4NNwi9kpXQ2kVtfm0fg/viewform" target="_blank" class="mt-6">Start Your application</a>

      </button>
  </div>
</div>



<div class="mt-16 w-full space-y-10 p-10 ">
  <div>
    <div class="bg-lightTangerine p-8 flex justify-between cursor-pointer" @click="toogleEligibility">
      <p class="font-rockinsoda text-navy  text-3xl lg:text-5xl">ELIGIBILITY</p>

      <i v-if="isEligibilityOpen" class=" text-navy fa fa-arrow-down bg-lightTangerine fa-3x" aria-hidden="true"></i>
      
      <i v-else class=" text-navy fa fa-arrow-right  fa-3x" aria-hidden="true"></i>

    </div>
    <div v-if="isEligibilityOpen" class="bg-lightTangerine">
      <p class="text-darknavy font-bold  font-gramatika text-xl lg:text-2xl p-6  ml-8"> Organisation</p>


      <div v-for="(eligible, index) in eligibility" :key="index">
       

          <div class="flex  space-x-4 bg-lightTangerine p-6 ">

            <i class="fa fa-check text-navy  fa-2x" aria-hidden="true"></i>
            <p class=" text-base lg:text-xl font-gramatika text-navy leading-relaxed" v-html="eligible.text"></p>

              
          </div>
       
      </div>

      <p class="text-darknavy font-bold font-gramatika text-xl lg:text-2xl ml-8 p-6">Applicant</p>

      <div v-for="(short, index) in shortlisted" :key="index">
       

        <div class="flex  space-x-4 bg-lightTangerine p-6 ">

          <i class="fa fa-check text-navy  fa-2x" aria-hidden="true"></i>
          <p class=" text-base lg:text-xl font-gramatika text-navy leading-relaxed">
            {{ short.short }}</p>
        </div>
     
    </div>

    </div>

  </div>

  <div>
    <div class=" space-x-4 lg:space-x-0 bg-lightTangerine p-8 flex justify-between cursor-pointer"
      @click="toogleCriteria">
      <p class="font-rockinsoda text-navy text-3xl lg:text-5xl">SELECTION CRITERIA</p>
      <i v-if="isCriteriaOpen" class=" text-navy fa fa-arrow-down  fa-3x" aria-hidden="true"></i>
      <i v-else class=" text-navy fa fa-arrow-right  fa-3x" aria-hidden="true"></i>

    </div>
    <div v-if="isCriteriaOpen" class="bg-lightTangerine">
      <p class="text-navy  font-gramatika text-xl p-6  ml-8">Applications will be assessed based on the following criteria:</p>

      <div v-for="(crit, index) in criteria" :key="index">
          <div class="flex  space-x-4  p-6 ">
            <i class="fa fa-check text-navy  fa-2x" aria-hidden="true"></i>
            <p class="text-base lg:text-xl text-navy font-gramatika leading-relaxed" v-html="crit.criteri">
</p>

          </div>
      

      </div>
    </div>
  </div>

</div> 



    


    <div class="w-full flex flex-col lg:flex-row  mt-16 p-4 lg:p-10 mb-10 justify-between">
     <div class="space-y-4 text-center lg:text-left">
        <p class="font-rockinsoda text-navy text-2xl sm:text-3xl">HAVE A QUESTION ABOUT THE</p>
        <p class="font-rockinsoda text-navy text-2xl sm:text-3xl">PROGRAM?</p>
        <button class="font-gramatika text-xl sm:text-2xl text-navy transition-all" ref="button">
          <div class="flex justify-center lg:justify-start space-x-4">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeImeTJfn5nCg1AG3OW_xiqSwpjlAM5F2H-c2BfdKcbUmov0Q/viewform" target="_blank" >Join info session</a>
            <img src="../assets/Arrow 1.svg" alt="Arrow Icon">
          </div>
        </button>
      </div> 

       <div class="space-y-4 mt-8 mr-0 lg:mr-20 lg:mt-0 text-center lg:text-left">
        <p class="font-gramatika text-navy text-lg sm:text-xl">Email us at:</p>
        <p class="font-gramatika text-navy text-lg sm:text-xl underline">srhprogram@hangapitchfest.rw</p>
        <p class="font-gramatika text-navy text-lg sm:text-xl">visit:</p>
        <router-link to="/srh-faq">
        <a href="" class="font-gramatika text-navy text-lg sm:text-xl">Hanga SRH FAQ</a>
      </router-link>
      </div> 

    </div>

    <scrollButton />
    <FooterPage />

    </div>
</div>

  </template>
<script>
import NavInit from '@/components/NavInit.vue';
import FooterPage from '@/components/footerPage.vue';
import scrollButton from '@/components/scrollButton.vue';
// import FilteredVentures from '@/components/FilteredVentures.vue';
export default {
  name: "HangaSrh",
  components: {
    NavInit,
    FooterPage,
    scrollButton,
    // FilteredVentures
  },

  data() {

    return {
      eligibility: [

      {
        text:`Must be <span class="font-bold">operating in any country in Sub-Saharan Africa.`
      },
        {
     text: `Your solution must focus on <span class="font-bold">one or more of the following SRH thematic areas:</span><br><br>  
     <ul class="list-disc text-lg [&>li::marker]:text-4xl">  
      <li> <span class="font-bold">Improving maternal health</span>, beyond antenatal care, ensuring safe pregnancy, childbirth, and postpartum care</li>
      <li> <span class="font-bold">Tackling unsafe abortion </span> by removing barriers to safe abortion services and post-abortion care</li> 
      <li><span class="font-bold">Targeting </span> young people (10-24 years) with <span class="font-bold">youth-friendly SRH information and services.</span></li> 
      <li> <span class="font-bold"> Tackling gender-based violence (GBV) </span> by preventing and responding to sexual and gender-based violence through innovative solutions</li>
      <li> <span class="font-bold"> Meeting  </span> the  <span class="font-bold">need for contraception  </span> by increasing access to contraception and comprehensive SRH education</li>
     </ul>`
  },

  {
    text:`The start-up must present a <span class="font-bold">tech-enabled SRH solution</span> or have the <span class="font-bold"> potential to scale through technology.</span> `,
  },
  {
    text:`Must present<span class="font-bold"> a working prototype.</span> This can be in the form of a working product demo in video format, a pitch deck explaining how the product works, a functional website, or anything  similar.`
  },
  {
    text:`Must be registered by <span class="font-bold"> the relevant authorities .</span> in the country of operation`
  },
  {
    text:`The start-up <span class="font-bold"> should not have raised any venture capital  .</span> (except angel investors, family, and friends). Start-ups that may have received grant funding from other incubator programs or hackathons are eligible.`
  }


       

      ],

      criteria: [

        {
          criteri: `<span class="font-bold">Impact (35%)</span> - Does the startup have the potential to make a meaningful difference in SRH outcomes? Is the challenge they are addressing significant?`
        },
        {
          criteri: `<span class="font-bold"> Mindset (20%)</span> - Is the founder curious, collaborative, and problem-driven? Are they open to learning and growing with their idea?`
        },
        {
          criteri: `<span class="font-bold"> Innovation (25%) </span> - Is the startup tackling an underfunded SRH issue or pioneering a new approach?`
        },
        {
          criteri: ` <span class="font-bold"> Sustainability (20%) </span> -Does the idea have the potential to be operationally and financially  sustainable at scale ?`
        },

      ],
      shortlisted:[
        
      {
        short:'Must be the Founder / Co-founder and/or hold primary decision-making power in the start-up',

      },
      {
        short:"Must be digitally literate with consistent access to a laptop and  internet",
      },
      {
        short:"Must be at least 18 years of age at the time of application",
      },
      {
        short:"Must have basic English proficiency",
      }


      ],

      slides: [
        {
          image: require('../assets/nsrh/5.jpg'),
          title: "Join Us If you are / 05",
          description: "Improving maternal health beyond the access to antenatal care for pregnant women.",
        },
        {
          image: require('../assets/nsrh/4.jpg'),
          title: "Join Us If you are / 04",
          description: "Tackling unsafe abortion by removing the barriers to accessing safe abortion",
        },
        {
          image: require('../assets/nsrh/3.jpg'),
          title: "Join Us If you are / 03",
          description: "Targeting young people (10-24)with youth-friendly SRH information and services. ",
        },
        {
          image: require('../assets/nsrh/2.jpeg'),
          title: "Join Us If you are / 02",
          description: "Tackling gender based violence including sexual violence. ",
        },
        {
          image: require('../assets/nsrh/1.jpg'),
          title: "Join Us If you are / 01",
          description: "Meeting the need for contraception including education about and access to contraception.",
        },

      ],

      isEligibilityOpen: false,
      isCriteriaOpen: false,
      currentSlide: 0

    }
  },

  methods: {

    toogleEligibility() {
      this.isEligibilityOpen = !this.isEligibilityOpen

    },

    toogleCriteria() {
      this.isCriteriaOpen = !this.isCriteriaOpen
    },

    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    },

  },



  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up-visible');
          entry.target.classList.add('zoom-in-visible');
          entry.target.classList.add('fade-in-visible');

        }
      })
    });


    const targetId = this.$route.hash.substring(1);
    const target = document.getElementById(targetId);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    // Observe all elements with the zoom-in class
    const elements = this.$refs;
    for (let key in elements) {
      if (elements[key]) {
        observer.observe(elements[key]);
      }
    }
    // Observe all elements with the fade-in class
    // const items = [this.$refs.item1, this.$refs.item2, this.$refs.item3];
    // items.forEach(item => {
    //   observer.observe(item);

    // });
  }
}

</script>

<style>
.slide-up {
  transform: translateY(20px);
  opacity: 0;
  transition: all 2s ease-in-out;
}

.slide-up-visible {
  transform: translateY(0);
  opacity: 1;
}

.zoom-in {
  transform: scale(0.95);
  opacity: 0;
  transition: transform 1s ease-in-out, opacity 0.90s ease-in-out;
}

.zoom-in-visible {
  transform: scale(1);
  opacity: 1;
}

.button-hover {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-hover:hover {
  transform: scale(1.05);
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in-visible {
  opacity: 1;
}

.fade-in:nth-child(1) {
  transition-delay: 0.3s;
}

.fade-in:nth-child(2) {
  transition-delay: 0.6s;
}

.fade-in:nth-child(3) {
  transition-delay: 0.9s;
}

/* General Fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s ease-in-out;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

/* Fade and Slide Up */
.fade-slide-down-enter-active {
  transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);
}

.fade-slide-down-enter-from {
  opacity: 0;
  transform: translateY(-20px);
  /* Start above */
}

.fade-slide-down-enter-to {
  opacity: 1;
  transform: translateY(0);
  /* End at original position */
}

/* Fade and Slide Left */
.fade-slide-left-enter-active {
  transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);
  /* Even slower and more natural */
}

.fade-slide-left-enter-from {
  opacity: 0;
  transform: translateX(-80px);
}

.fade-slide-left-enter-to {
  opacity: 1;
  transform: translateX(0);
}

/* Fade and Slide Right */
.fade-slide-right-enter-active {
  transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);
  /* Even slower and more natural */
}

.fade-slide-right-enter-from {
  opacity: 0;
  transform: translateX(80px);
}

.fade-slide-right-enter-to {
  opacity: 1;
  transform: translateX(0);
}

/* Zoom In */
.zoom-enter-active {
  transition: all 0.7s ease;
}

.zoom-enter-from {
  opacity: 0;
  transform: scale(0.9);
}

.zoom-enter-to {
  opacity: 1;
  transform: scale(1);
}

.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: opacity 1.1s ease-in-out, transform 1.1s ease-in-out;
}

.fade-slide-up-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade-slide-up-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.fade-slide-up-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.fade-slide-up-leave-to {
  opacity: 0;
  transform: translateY(80px);
}
</style>