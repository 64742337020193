<template>
    <div class="min-h-screen">
      <div class="relative h-screen min-h-[500px] max-h-[670px] sm:max-h-[800px] lg:max-h-[950px] xl:max-h-[1000px]">
        <div class="absolute inset-0 bg-black opacity-30"></div>
        <img src="../assets/faqbg.jpg" alt="Background" class="h-full w-full object-cover" />
        
        <NavInit class="absolute top-0 left-0 w-full" />
  
        <div class="font-rockinsoda absolute text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl top-2/4 left-4 sm:left-8 md:left-12 lg:left-16 xl:left-20 text-center sm:text-left" ref="text">
          <p>FREQUENTLY ASKED</p>
          <p>QUESTIONS?</p>
        </div>
      </div>
  
      <!-- Middle section -->
      <div class="w-full mt-20 md:mt-10">
        <p class=" p-5 font-gramatika text-navy text-2xl font-medium" >a. Applications & Eligibility</p>

        <!-- First row -->
        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-8 sm:space-y-0 sm:space-x-12">

          
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">What stage of growth does my program need to be to be eligible for the program?</p>
            <p class="font-gramatika text-gray-500">Early stage start-ups that have not raised any <span class="font-bold">equity funding from venture capital firms</span>. Start-ups that have received grant funding from incubator programs, hackathons, angel investors, family, or friends are eligible to apply</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Which SRH areas do I need to be innovating in to be eligible for the program?</p>
            <p class="font-gramatika text-gray-500">The program  aims to support innovative and tech-enabled solutions for SRH pertinent challenges such as but not limited to maternal mortality , unsafe abortion, gender based violence, unmet need for contraception, and limited access to youth-friendly SRH services.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Does my SRH solution need to be tech-enabled?</p>
            <p class="font-gramatika text-gray-500">Yes, the start-up must present a tech-enabled solution or have the potential to scale through technology.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
        </div>
  
        <!-- Second row -->
        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12">
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Do I need to have a working prototype?</p>
            <p class="font-gramatika text-gray-500">Yes, working prototypes are required. This can be in the form of a working product demo in video format, a pitch deck explaining how the product works, a functional website, or anything similar.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">I work closely in the development of a SRH product. Can I join the program?</p>
            <p class="font-gramatika text-gray-500">To be eligible for the program you must be the Founder / Co-founder and/or hold primary decision-making power in the business.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Where do I need to be based to be eligible for the program?</p>
            <p class="font-gramatika text-gray-500">You need to be based in a Sub-Saharan Africa country or in the diaspora with a SRH focused start-up based (registered and operating) in a Sub-Saharan Africa country.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
        </div>
  
        <!-- Third row -->
        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12  ">
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Can a start-up have more than one person joining the program?</p>
            <p class="font-gramatika text-gray-500">Yes, but this will be evaluated on a need-by-need basis with further guidelines given once the SRH innovator is accepted to the program.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">How will applications be assessed?</p>
            <p class="font-gramatika text-gray-500">Applications will be assessed based on criteria including potential impact, entrepreneurial mindset, innovation in addressing SRH issues, and commercial viability of the idea in the marketplace.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">I have applied for registration of my business, but I am yet to receive it due to delays in government processes. Can I still apply?</p>
            <p class="font-gramatika text-gray-500">Yes. As long as you are able to provide this before admission into the program.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
        </div>
      </div>

      <!-- Middle section -->
      <div class="w-full mt-20 md:mt-10">
        <p class=" p-5 font-gramatika text-navy text-2xl font-medium" >b. Program</p>

        <!-- First row -->
        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12">

          
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">How long will the program take to complete?</p>
            <p class="font-gramatika text-gray-500">The program will be 8 months long consisting of an incubation period of 2-months, consisting of a cohort-based learning curriculum covering entrepreneurship, evidence, and SRH. Following this initial phase, eligible start-ups will receive 6 months of 1:1 bespoke support from venture-building and SRH experts.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">How much funding will I receive from the program?</p>
            <p class="font-gramatika text-gray-500">The grant funding is valued at $10,000 USD with the scope to increase up to $30,000 USD.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">What other type of support will I receive from the program?</p>
            <p class="font-gramatika text-gray-500">Cohort-based learning curriculum covering entrepreneurship, evidence, and SRH. Eligible start-ups will also receive 1:1 bespoke support from venture-building and SRH experts.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
        </div>
  
        <!-- Second row -->
        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12">
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Any expected travel during the duration of the program?</p>
            <p class="font-gramatika text-gray-500">Yes, travelling to Rwanda for some of the events. More details to be shared prior to admission into the program.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">How owns the Intellectual property (IP) of the product you work on during the duration of the program?</p>
            <p class="font-gramatika text-gray-500">The entrepreneur owns full intellectual property rights of their Product.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Through the program can I get support to set my start-up in Rwanda?</p>
            <p class="font-gramatika text-gray-500">Rwanda is open for business. If interested in operating in Rwanda, the team will support on a case by case basis. Overall information on the business environment in Rwanda will be shared over the course of the program.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
        </div>
  
        <!-- Third row -->
        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12  ">
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Is this program full or part time</p>
            <p class="font-gramatika text-gray-500">The program will be part time, with learning journeys being focussed on giving start-ups time and space to apply learnings</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">How will applications be assessed?</p>
            <p class="font-gramatika text-gray-500">Applications will be assessed based on criteria including potential impact, entrepreneurial mindset, innovation in addressing SRH issues, and commercial viability of the idea in the marketplace.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">I have applied for registration of my business, but I am yet to receive it due to delays in government processes. Can I still apply?</p>
            <p class="font-gramatika text-gray-500">Yes. As long as you are able to provide this before admission into the program.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
        </div>
      </div>

      <!-- Last section -->
      <div class="w-full  mt-10 md:mt-10">
        <p class=" p-5 font-gramatika text-navy text-2xl font-medium" >c. Proficiencies</p>

        <!-- First row -->
        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12">

          
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">What is the level of digital competence required for the program?</p>
            <p class="font-gramatika text-gray-500">Essential digital skills such as but not limited to using a laptop/smartphone, communicating online using email, Zoom and/or Google Meets are needed for the participants to get the most out of this course.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
        </div>
  
      </div>
      <FooterPage  class="mt-20"/>
    </div>
  </template>

<script>
import NavInit from '@/components/NavInit.vue';
import FooterPage from '@/components/footerPage.vue';

export default{
    name:"SrhFaqPage",
    components:{
        NavInit,
        FooterPage
    }
}
</script>