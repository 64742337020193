<template>
    <div class="min-h-screen">
      <div class="relative h-screen min-h-[500px] max-h-[670px] sm:max-h-[800px] lg:max-h-[950px] xl:max-h-[1000px]">
        <div class="absolute inset-0 bg-black opacity-60"></div>
        <img src="../assets/ppl.png" alt="Background" class="h-full w-full object-cover" />
        
        <NavInit class="absolute top-0 left-0 w-full" />
  
        <div class="font-rockinsoda absolute text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl top-2/4 left-4 sm:left-8 md:left-12 lg:left-16 xl:left-20 text-center sm:text-left" ref="text">
          <p>MEET THE FIRST HANGA SRH</p>
          <p>PROGRAM COHORT</p>
        </div>

      </div>
      
      <FilteredVentures/>
  
     

      <FooterPage  class="mt-20"/>
    </div>
  </template>

<script>
import NavInit from '@/components/NavInit.vue';
import FooterPage from '@/components/footerPage.vue';
import FilteredVentures from '@/components/FilteredVentures.vue';

export default{
    name:"SrhFaqPage",
    components:{
        NavInit,
        FooterPage,
        FilteredVentures
    }
}
</script>